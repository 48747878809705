export interface OptionType {
  label?: string;
  value?: string;
}

export const roleOptions: OptionType[] = [
  {
    label: "前端 (HTML/JS/React)",
    value: "frontend engineer",
  },
  {
    label: "后端 (APIs/Databases)",
    value: "backend engineer",
  },
  {
    label: "全栈",
    value: "fullstack engineer",
  },
  {
    label: "SQL查询和基本编码",
    value: "business analyst",
  },
  {
    label: "非技术",
    value: "non technical",
  },
  {
    label: "其他",
    value: "other",
  },
];

export const useCaseOptions: OptionType[] = [
  {
    label: "只是探索",
    value: "just exploring",
  },
  {
    label: "个人项目",
    value: "personal project",
  },
  {
    label: "工作项目",
    value: "work project",
  },
  {
    label: "其他",
    value: "other",
  },
];

export const proficiencyOptions = [
  {
    label: "Just dipping my toes 🌱 (Brand new to this)",
    value: "Beginner",
  },
  { label: "Novice (Limited to no experience)", value: "Novice" },
  { label: "Intermediate (Some coding adventures)", value: "Intermediate" },
  {
    label: "Advanced (Comfortable with programming quests)",
    value: "Advanced",
  },
];
